import Common from './common';

class JoinUs extends Common {
  onEnter() {
    super.onEnter({
      scenes: {
        pageAside: {
          cache({ cache: { offset } }) {

            return {
              offset: offset + 100,
            };
          },
        }
      }
    });
  }

  // onEnterCompleted() {
  //   super.onEnterCompleted();
  // }

  // onLeave() {
  //   super.onLeave();
  // }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }
}


export default JoinUs;
