import Highway from '@dogstudio/highway/dist/es5/highway';
import anime from 'animejs';
export default class Investors extends Highway.Transition {
  in(view, done) {
    const timeline = anime.timeline({ complete: done });
    timeline
      .add({
        targets: view,
        duration: 300,
        opacity: [0, 1],
        easing: 'easeOutQuad',
      })
      .add({
        targets: view.querySelectorAll('.reason'),
        duration: 1000,
        opacity: [0, 1],
        translateY: [60, 0],
        delay: (el, i, l) => 200 + i * 80,
        easing: 'easeOutQuint',
      });
  }

  out(view, done) {
    const timeline = anime.timeline({ complete: done });
    timeline
      .add({
        targets: view.querySelectorAll('.reason'),
        duration: 220,
        delay: 600,
        opacity: [1, 0],
        translateY: [0, 60],
        delay: (el, i, l) => i * 50,
        easing: 'easeInQuad',
      })
      .add({
        targets: view,
        duration: 300,
        opacity: [1, 0],
        easing: 'easeInQuad',
        offset: '-=50',
      });
  }
}
