import anime from 'animejs';

export default class Loader {
  constructor(context) {
    this.DOM = { context };
  }

  hide(callback) {
    return anime({
      targets: this.DOM.context,
      opacity: 0,
      scale: 1.5,
      easing: 'easeInCubic',
      complete: () => {
        callback && callback();
        document.body.classList.add('loaded');
      },
    }).finished;
  }

  destroy() {
    this.DOM.context.parentNode.removeChild(this.DOM.context);
  }
}
