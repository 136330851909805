import Highway from "@dogstudio/highway/dist/es5/highway";
import rolly from "rolly.js";

class Common extends Highway.Renderer {
  onEnter(rollyOptions) {
    this.options = {
      shrinkHeader: 50
    };

    this.viewName = this.view.getAttribute("router-view");

    const viewsWithRolly = [
      "startups",
      "joinUs",
      "investors",
      "ourPartners",
      "contact"
    ];

    if (viewsWithRolly.includes(this.viewName)) {
      const rol = rolly({
        view: this.view.querySelector(".page__inner"),
        preload: true,
        native: false,
        change: state => {
          window.highway.emit(
            "header::shrink",
            state.current > this.options.shrinkHeader
          );
        },
        ...rollyOptions
      });
      rol.init();
      this.rolly = rol;
    }

    const buttons = [...this.view.querySelectorAll(".btn")];
    buttons.forEach(button => {
      if (!button.getAttribute("data-text") && button.childNodes) {
        button.setAttribute("data-text", button.childNodes[0].data.trim());
      }
    });

    this.bindEvents();
  }

  onEnterCompleted() {
    // console.log('on enter completed');
  }

  onLeave() {
    // console.log('on leave', this);
    this.unbindEvents();
  }

  onLeaveCompleted() {
    // console.log('on leave completed');
    if (this.rolly) {
      this.rolly.destroy();
    }
    window.highway.emit("header::shrink", false);
  }

  bindEvents() {
    this.boundFns = {
      onScroll: this.onScroll.bind(this),
      onOpenDialog: this.onOpenDialog.bind(this)
    };

    if (!this.rolly) {
      window.addEventListener("scroll", this.boundFns.onScroll);
    }

    const dialogOpeners = [...this.view.querySelectorAll("[data-open-dialog]")];
    dialogOpeners.forEach(dialogOpener => {
      dialogOpener.addEventListener("click", this.onOpenDialog);
    });
  }

  unbindEvents() {
    if (!this.rolly) {
      window.removeEventListener("scroll", this.boundFns.onScroll);
    }

    const dialogOpeners = [...this.view.querySelectorAll("[data-open-dialog]")];
    dialogOpeners.forEach(dialogOpener => {
      dialogOpener.removeEventListener("click", this.onOpenDialog);
    });

    delete this.boundFns;
  }

  onScroll() {
    window.highway.emit(
      "header::shrink",
      window.scrollY > this.options.shrinkHeader
    );
  }

  onOpenDialog(event) {
    const name = event.target.getAttribute("data-open-dialog");
    let src;

    if (name === "invest") {
      src = "21049461/1453258";
    } else if (name === "apply") {
      src = "21781724/1519571";
    }

    highway.emit("dialog::open", {
      name,
      HTML: `<iframe
        class="podio-webform-frame"
        id="podioWebForm1453253534136"
        style="width: 100%;  height: 80vh; border: none;"
        allowtransparency="true"
        frameborder="0"
        src="https://podio.com/webforms/${src}?e=true"></iframe>`
    });
  }
}

export default Common;
