/*
 * Async
 */

const loadImage = src => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = event => resolve(event);
    image.src = src
  });
};

/*
 * Device
 */

const getDeviceType = () => {
  let device = deviceList.smallPhone;
  if (window.innerWidth >= mediaQueries.mediumPhone) {
    device = deviceList.mediumPhone;
  }
  if (window.innerWidth >= mediaQueries.largePhone) {
    device = deviceList.largePhone;
  }
  if (window.innerWidth >= mediaQueries.tablet) {
    device = deviceList.tablet;
  }
  if (window.innerWidth >= mediaQueries.desktop) {
    device = deviceList.desktop;
  }
  if (window.innerWidth >= mediaQueries.largeDesktop) {
    device = deviceList.largeDesktop;
  }
  return device;
};

const isTouchDevice = () => 'ontouchstart' in window /*Works on most browsers*/
  || navigator.maxTouchPoints; /*Works on IE10/11 and Surface*/

const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

/*
* Style
*/

const css = (el, styles) => {
  for (let [property, value] of Object.entries(styles)) {
    el.style[property] = value;
  }
};


/*
 * A11y
 */

const setCursor = cursor => css(document.documentElement, { cursor });

/*
 * Events
 */

const debounce = (callback, delay = 100) => {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      callback.apply(this, args)
    }, delay)
  }
};

const throttle = (callback, delay = 100) => {
  let timer = null
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        callback.apply(this, args)
        timer = null
      }, delay)
    }
  }
};

/*
 * Data
 */

const shuffle = a => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
  }
  return a;
}

export default {
  loadImage,
  getDeviceType,
  isTouchDevice,
  isFirefox,
  css,
  setCursor,
  debounce,
  throttle,
  shuffle,
};
