module.exports={
  "mediaQueries": {
    "mediumPhone": 320,
    "largePhone": 500,
    "tablet": 768,
    "desktop": 992,
    "largeDesktop": 1200
  },

  "deviceList": {
    "smallPhone": "small-phone",
    "mediumPhone": "medium-phone",
    "largePhone": "large-phone",
    "tablet": "tablet",
    "desktop": "desktop",
    "largeDesktop": "large-desktop"
  },

  "preload": {
    "images": [
      "./images/background-home.jpg",
      "./images/background-startups.jpg",
      "./images/background-join-us.jpg",
      "./images/background-investors.jpg",
      "./images/background-our-partners.jpg",
      "./images/background-contact.jpg",
      "./images/the-deal/deadlines.jpg",
      "./images/the-deal/our-selection.jpg",
      "./images/the-deal/we-ask-for.jpg",
      "./images/the-deal/we-provide.jpg",
      "./images/our-partners/logo-ashoka.png",
      "./images/startups/collecticity-1.jpg",
      "./images/startups/motosacoche-1.jpg"
    ]
  },

  "imageDistorter": {
    "images": [
      "./images/background-home.jpg",
      "./images/background-startups.jpg",
      "./images/background-join-us.jpg",
      "./images/background-investors.jpg",
      "./images/background-our-partners.jpg",
      "./images/background-contact.jpg",
      "./images/the-deal/deadlines.jpg",
      "./images/the-deal/our-selection.jpg",
      "./images/the-deal/we-ask-for.jpg",
      "./images/the-deal/we-provide.jpg"
    ]
  }
}
