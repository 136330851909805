import Common from './common';

class OurPartners extends Common {
  // onEnter() {
  //   super.onEnter();
  // }

  // onEnterCompleted() {
  //   super.onEnterCompleted();
  // }

  // onLeave() {
  //   super.onLeave();
  // }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }
}


export default OurPartners;
