import Highway from '@dogstudio/highway/dist/es5/highway';
import anime from 'animejs';

export default class Fade extends Highway.Transition {
  in(view, done) {
    anime({
      targets: view,
      duration: 300,
      delay: 600,
      opacity: [0, 1],
      easing: 'easeOutQuad',
      complete: done,
    });
  }

  out(view, done) {
    anime({
      targets: view,
      duration: 300,
      opacity: [1, 0],
      easing: 'easeInQuad',
      complete: done,
    });
  }
}
