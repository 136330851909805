import utils from '../utils';

import Common from './common';
import HomeSlider from '../components/homeSlider';


class Home extends Common {
  static getSliderStartIndex() {
    const { hash } = window.location;
    return hash.slice(1, hash.length - 2) === 'what-is-the-deal'
      ? parseInt(hash.slice(-1))
      : 0;
  }

  onEnter() {
    super.onEnter();

    if (!utils.isTouchDevice()) {
      const slider = this.view.querySelector('.page__inner');
      setTimeout(() => {
        const startIndex = Home.getSliderStartIndex();

        if (startIndex > 0) {
          highway.emit('header::activeView', 'wtd');
        }

        this.slider = new HomeSlider(slider, {
          startIndex,
          slideChanged: (data) => {
            const viewName = data.currentIndex > 0 ? 'wtd' : null;
            highway.emit('header::activeView', viewName);
          }
        });
      }, 0);

      // Bind additionnal events
      this.boundFns = {
        ...this.boundFns, ... {
          onClickLink: this.onClickLink.bind(this),
        }
      };

      const links = [...document.querySelectorAll('[data-slider-go-to]')];
      links.forEach(link => {
        link.addEventListener('click', this.boundFns.onClickLink);
      });
    }
  }

  // onEnterCompleted() {
  //   super.onEnterCompleted();
  // }

  onLeave() {
    super.onLeave();
    if (this.slider) {
      this.slider.destroy();
    }
  }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }

  unbindEvents() {
    if (this.boundFns.onClickLink) {
      const links = [...document.querySelectorAll('[data-slider-go-to]')];
      links.forEach(link => {
        link.removeEventListener('click', this.boundFns.onClickLink);
      })
    }

    super.unbindEvents();
  }

  onClickLink(event) {
    event.preventDefault();
    const index = parseInt(event.currentTarget.getAttribute('data-slider-go-to'));
    if (!isNaN(index)) {
      this.slider.changeSlide(index);
    }
  }
}


export default Home;
