import GoogleMapsLoader from "google-maps";
import xhr from "xhr";
import anime from "animejs";

import Common from "./common";

class Contact extends Common {
  onEnter() {
    super.onEnter();
    document.getElementById("map").style.opacity = 0;
  }

  onEnterCompleted() {
    super.onEnterCompleted();
    setTimeout(() => this.initMap(), 0);
  }

  // onLeave() {
  //   super.onLeave();
  // }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }

  bindEvents() {
    super.bindEvents();

    this.boundFns = {
      ...this.boundFns,
      ...{
        onBlurField: this.onBlurField.bind(this),
        onSubmit: this.onSubmit.bind(this)
      }
    };

    ["name", "email", "subject", "message"].map(fieldName => {
      const field = document.getElementById(`form_${fieldName}`);
      field.addEventListener("blur", this.boundFns.onBlurField);
    });

    const btn = document.getElementById("form_submit");
    btn.addEventListener("click", this.boundFns.onSubmit);
  }

  unbindEvents() {
    ["name", "email", "subject", "message"].map(fieldName => {
      const field = document.getElementById(`form_${fieldName}`);
      field.removeEventListener("blur", this.boundFns.onBlurField);
    });

    const btn = document.getElementById("form_submit");
    btn.removeEventListener("click", this.boundFns.onSubmit);

    super.unbindEvents();
  }

  initMap() {
    GoogleMapsLoader.KEY = "AIzaSyCU9YTFHgnCNPXLjBvHsO7T5s3H0eEyPoM";
    const MAJPos = { lat: 46.21194, lng: 6.12504 };
    const options = {
      zoom: 15,
      center: MAJPos,
      zoomControl: false,
      gestureHandling: "cooperative"
    };

    GoogleMapsLoader.load(google => {
      const map = new google.maps.Map(document.getElementById("map"), options);

      const marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        position: MAJPos,
        map,
        title: "MAJ Ventures",
        icon: "images/maj-marker.png"
      });

      const infoWindow = new google.maps.InfoWindow();
      google.maps.event.addListener(marker, "click", function() {
        infoWindow.setContent(
          '<div class="-o-text-dark -o-text-left">' +
            '<strong class="-o-title-2">MAJ digital</strong><br>' +
            "Chemin du Bois-Gentil 7, 1203 Genève, Switzerland<br>" +
            '<a href="https://maj.digital" target="_blank" class="-o-text-link">https://maj.digital</a><br>' +
            "</div>"
        );
        infoWindow.open(map, this);
      });

      const opts = {
        targets: document.getElementById("map"),
        opacity: 1,
        translateZ: 0,
        scale: [0.9, 1],
        easing: "easeOutQuint",
        duration: 1000,
        delay: 1500
      };
      opts[
        matchMedia("(min-width: 992px)").matches ? "translateX" : "translateY"
      ] = [100, 0];
      anime(opts);
    });
  }

  validateForm() {
    let isValid = true;
    ["name", "email", "subject", "message"].map(fieldName => {
      const field = document.getElementById(`form_${fieldName}`);
      if (!this.validateField(field)) {
        isValid = false;
      }
    });
    return isValid;
  }

  validateField(field) {
    if (!field.value) {
      field.classList.add("has-error");
      return false;
    }
    field.classList.remove("has-error");
    return true;
  }

  sendEmail() {
    const apiUrl = "https://mandrillapp.com/api/1.0/messages/send.json";
    const email = document.getElementById("form_email").value;
    const name = document.getElementById("form_name").value;
    const subject = document.getElementById("form_subject").value;
    const message = document.getElementById("form_message").value;

    const body = JSON.stringify({
      key: "q2SnvdjLVFrLMTl5a00uVQ", // TODO: Setup a key
      message: {
        from_email: "noreply@maj.digital", // TODO: change email
        from_name: name,
        headers: {
          "Reply-To": email
        },
        subject,
        text: message,
        to: [
          {
            email: "paul@maj.digital",
            name: "Paul Merz",
            type: "to"
          }
        ]
      }
    });

    xhr.post(apiUrl, { body }, (err, res, body) => {
      if (res.statusCode === 200) {
        this.onEmailSent(JSON.parse(body));
      } else if (err) {
        this.onEmailError(err);
      }
    });
  }

  onBlurField(e) {
    this.validateField(e.target);
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();
    if (isValid) {
      this.sendEmail();
    }
  }

  onEmailError(err) {
    console.error(err);
    alert("Email could not be sent");
  }

  onEmailSent(res) {
    console.log(res);
    alert("Email has been sent!");
  }
}

export default Contact;
