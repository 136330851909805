import { Lethargy } from 'lethargy';

import HomeSliderItem from './homeSliderItem';

export default class HomeSlider {
  constructor(context, options = {}) {
    this.DOM = { context };

    const defaultOptions = { className: 'home-slider', startIndex: 0 };
    this.options = { ...defaultOptions, ...options };

    this.init();
  }

  init() {
    this.DOM.context.classList.add(this.options.className);

    this.slides = [];
    const slides = this.DOM.context.querySelectorAll('.section');

    [...slides].forEach((slide, index) => {
      const sliderItem = new HomeSliderItem(slide, {
        pageHeading: index === 0,
        hiddenByDefault: index !== this.options.startIndex,
        appear: data => window.highway.emit('background::update', data),
      });
      this.slides.push(sliderItem);
    });

    this.state = {
      currentIndex: this.options.startIndex,
      isAnimating: false,
    };

    this.bindEvents();
  }

  destroy() {
    this.DOM.context.classList.remove('home-slider');
    this.unbindEvents();
  }

  bindEvents() {
    this.lethargy = new Lethargy();
    this.boundFns = {
      onKeyDown: this.onKeyDown.bind(this),
      onScroll: this.onScroll.bind(this),
    };

    document.addEventListener('keydown', this.boundFns.onKeyDown);

    // IE9, Chrome, Safari, Opera
    window.addEventListener("mousewheel", this.boundFns.onScroll);
    // Firefox
    window.addEventListener("DOMMouseScroll", this.boundFns.onScroll);
  }

  unbindEvents() {
    document.removeEventListener('keydown', this.boundFns.onKeyDown);

    window.removeEventListener("mousewheel", this.boundFns.onScroll);
    window.removeEventListener("DOMMouseScroll", this.boundFns.onScroll);

    delete this.boundFns;
  }

  /**
   * Change the slide.
   * @param {Number|boolean} nextSlide - The next slide. Can be a boolean or the index of the desired slide
   * @return {Number|boolean} Returns the effective next slide or false if no changes are performed.
   */
  changeSlide(nextSlide = true) {
    if (this.state.isAnimating) return false;

    const { currentIndex } = this.state;
    const nextIndex = Number.isInteger(nextSlide)
      ? nextSlide
      : currentIndex + (nextSlide ? 1 : -1);

    if (nextIndex === currentIndex) return false;
    if (nextIndex < 0 || nextIndex >= this.slides.length) return false;

    this.state.isAnimating = true;

    const direction = nextIndex > currentIndex;

    const disappear = this.slides[currentIndex].disappear({ direction });
    const appear = this.slides[nextIndex].appear({ direction, offset: 300 });

    Promise
      .all([disappear.finished, appear.finished])
      .then(() => {
        this.state.isAnimating = false;
        this.state.currentIndex = nextIndex;

        const hash = this.slides[this.state.currentIndex].DOM.context.id;
        window.location.hash = hash;

        if (this.options.slideChanged) {
          this.options.slideChanged({
            previousIndex: currentIndex,
            currentIndex: nextIndex,
          });
        }
      });

    return nextIndex;
  }

  onKeyDown(event) {
    const keyCode = event.keyCode || event.which;
    switch (keyCode) {
      case 37: // left key
      case 38: // up key
        this.changeSlide(false);
        break;
      case 39: // right key
      case 40: // down key
        this.changeSlide();
        break;
    }
  }

  onScroll(event) {
    event.stopPropagation();

    const check = this.lethargy.check(event);
    if (check) {
      this.changeSlide(check === -1);
    }
  }
}
