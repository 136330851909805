import Highway from '@dogstudio/highway/dist/es5/highway';
import anime from 'animejs';

export default class OurPartners extends Highway.Transition {
  in(view, done) {
    const timeline = anime.timeline({ complete: done });
    timeline
      .add({
        targets: view,
        duration: 300,
        delay: 600,
        opacity: [0, 1],
        easing: 'easeOutQuad',
      })
      .add({
        targets: view.querySelectorAll('.partner'),
        duration: 1500,
        opacity: [0, 1],
        scale: [1.2, 1],
        delay: (el, i, l) => 200 + i * 150,
        easing: 'easeOutQuint',
      });
  }

  out(view, done) {
    const timeline = anime.timeline({ complete: done });
    timeline
      .add({
        targets: view.querySelectorAll('.partner'),
        duration: 500,
        opacity: [1, 0],
        translateX: -20,
        delay: (el, i, l) => (l - i) * 50,
        easing: 'easeInQuad',
      })
      .add({
        targets: view,
        duration: 300,
        opacity: [1, 0],
        easing: 'easeInQuad',
        offset: '-=50',
      });
  }
}
