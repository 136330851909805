import { lory } from 'lory.js';

import StartupSliderItem from './startupSliderItem';

export default class StartupSlider {
  constructor(context, options) {
    this.DOM = { context };

    const defaultOptions = {
      enableMouseEvents: true,
      slideSpeed: 1000,
      snapBackSpeed: 1000,
      ease: null, // set in CSS
      rewind: true,
    };
    this.options = { ...defaultOptions, ...options };

    this.init();
  }

  init() {
    this.slider = lory(this.DOM.context, this.options);

    this.slides = [];
    const slides = this.DOM.context.querySelectorAll('.slider__slide');

    [...slides].forEach((slide, index) => {
      const sliderItem = new StartupSliderItem(slide, {
        hiddenByDefault: index !== 0,
        index
      }, this.slider);
      this.slides.push(sliderItem);
    });

    this.bindEvents();
  }

  destroy() {
    this.unbindEvents();
    if (this.slider) {
      this.slides.forEach(slide => { slide.destroy() });
      this.slider.destroy();
    }
  }

  bindEvents() {
    this.boundFns = {
      onKeyDown: this.onKeyDown.bind(this),
      onResize: this.onResize.bind(this),
      beforeSlide: this.beforeSlide.bind(this),
    };

    document.addEventListener('keydown', this.boundFns.onKeyDown);
    this.DOM.context.addEventListener('on.lory.resize', this.boundFns.onResize)
    this.DOM.context.addEventListener('before.lory.slide', this.boundFns.beforeSlide);
  }

  unbindEvents() {
    document.removeEventListener('keydown', this.boundFns.onKeyDown);
    this.DOM.context.removeEventListener('on.lory.resize', this.boundFns.onResize)
    this.DOM.context.removeEventListener('before.lory.slide', this.boundFns.beforeSlide);

    delete this.boundFns;
  }

  onKeyDown(event) {
    const keyCode = event.keyCode || event.which;
    switch (keyCode) {
      case 37: // left key
        this.slider.prev();
        break;
      case 39: // right key
        this.slider.next();
        break;
    }
  }

  onResize(event) {
    this.slides.forEach(slide => {
      slide.onResize();
    })
  }

  beforeSlide(event) {
    let { index, nextSlide } = event.detail;
    if (this.options.rewind && nextSlide >= this.slides.length) nextSlide = 0;
    if (!this.slides[nextSlide]) return;
    const direction = nextSlide > index;
    this.slides[index].disappear(direction);
    this.slides[nextSlide].appear(direction);
  }
}
