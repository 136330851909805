import Common from './common';

class JoinUs extends Common {
  onEnter() {
    super.onEnter();
    fbq('track', 'SubmitApplication', {type: 'Talent'});
  }

  // onEnterCompleted() {
  //   super.onEnterCompleted();
  // }

  // onLeave() {
  //   super.onLeave();
  // }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }
}


export default JoinUs;
