class Dialog {
  constructor(context) {
    this.DOM = { context };

    this.state = {
      open: false,
      name: null,
    };

    this.bindEvents();
  }

  bindEvents() {
    this.boundFns = {
      onDocumentClick: this.onDocumentClick.bind(this),
      open: data => this.open(data),
      close: data => this.close(data),
      close2: this.close.bind(this),
    };

    window.highway.on('dialog::open', this.boundFns.open);
    window.highway.on('dialog::close', this.boundFns.close);

    this.DOM.context
      .querySelector('.dialog__close')
      .addEventListener('click', this.boundFns.close2);
  }

  open(data) {
    if (this.state.open) return;

    if (data.HTML && this.state.name !== data.name) this.DOM.context.children[1].innerHTML = data.HTML;
    this.state.name = data.name;

    this.DOM.context.classList.add('is-visible');
    document.documentElement.classList.add('dialog-is-open');

    setTimeout(() => {
      document.addEventListener('click', this.boundFns.onDocumentClick);
      this.state.open = true;
      window.highway.emit('scroll::disable');
    }, 0);

    return this.state.open;
  }

  close() {
    if (!this.state.open) return;
    this.state.open = false;
    window.highway.emit('scroll::enable');

    this.DOM.context.classList.remove('is-visible');

    document.removeEventListener('click', this.boundFns.onDocumentClick);


    return this.state.open;
  }

  onDocumentClick(event) {
    if (!this.state.open) return;

    if (event.path.find(element => element === this.DOM.context) === undefined) {
      this.close();
    }
  }

  unbindEvents() {
    document.removeEventListener('click', this.boundFns.onDocumentClick);

    window.highway.off('dialog::open', this.boundFns.open);
    window.highway.off('dialog::close', this.boundFns.close);

    this.DOM.context
      .querySelector('.dialog__close')
      .removeEventListener('click', this.boundFns.close2);
  }

  destroy() {
    this.unbindEvents();
  }
}

export default Dialog;
