import anime from 'animejs';

export default class HomeSliderItem {
  constructor(context, options) {
    this.DOM = { context };
    this.state = { visible: true };

    options = {
      ...{
        pageHeading: false,
        hiddenByDefault: true,
        className: 'home-slider'
      }, ...options
    };
    this.options = options;

    if (options.hiddenByDefault) {
      this.disappear({ animated: false });
    } else {
      this.appear({ animated: false })
    }

    context.classList.add(`${options.className}__slide`);
  }

  disappear(options) {
    options = {
      ...{
        animated: true,
        offset: 0,
      }, ...options
    };

    const classList = [
      'is-disappearing',
      `is-disappearing--${options.direction ? 'top' : 'bottom'}`
    ];

    const timeline = anime.timeline({
      begin: () => {
        classList.map(cls => this.DOM.context.classList.add(cls));
        this.DOM.context.style.zIndex = 1;
      },
      complete: () => {
        classList.map(cls => this.DOM.context.classList.remove(cls));
        this.DOM.context.classList.remove('is-active');
        this.state.visible = false;
      }
    });

    const DOM = this.getElements();
    const translateY = this.getTranslateY();

    timeline
      .add({
        targets: Object.entries(DOM)
          .map(p => p[0] === 'heading' || p[0] === 'content' ? p[1] : null)
          .filter(el => el),
        translateY: translateY * (options.direction ? -1 : 1),
        opacity: 0,
        easing: 'easeInCubic',
        duration: options.animated ? 400 : 0,
        delay: () => anime.random(0, 200),
        offset: options.offset,
      })
      .add({
        targets: this.DOM.context,
        translateZ: 0,
        opacity: 0,
        easing: 'easeInCubic',
        duration: options.animated ? 500 : 0,
        offset: '-=100',
      })

    return timeline;
  }

  appear(options) {
    options = {
      ...{
        animated: true,
        offset: 0,
      }, ...options
    };

    const classList = [
      'is-appearing',
      `is-appearing--${options.direction ? 'bottom' : 'top'}`
    ];

    const timeline = anime.timeline({
      begin: () => {
        classList.map(cls => this.DOM.context.classList.add(cls));
        this.DOM.context.style.zIndex = 2;
        this.state.visible = true;

        if (this.options.appear) {
          this.options.appear(this.getSlideData());
        }
      },
      complete: () => {
        classList.map(cls => this.DOM.context.classList.remove(cls));
        this.DOM.context.classList.add('is-active');
      }
    });

    const DOM = this.getElements();
    const translateY = this.getTranslateY();

    timeline
      .add({
        targets: this.DOM.context,
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutCubic',
        duration: options.animated ? 500 : 0,
        offset: options.offset,
      })
      .add({
        targets: Object.entries(DOM)
          .map(p => p[0] === 'heading' || p[0] === 'content' ? p[1] : null)
          .filter(el => el),
        translateY: [translateY * (options.direction ? 1 : -1), 0],
        opacity: [0, 1],
        easing: 'easeOutQuint',
        duration: options.animated ? 800 : 0,
        delay: () => anime.random(0, 200),
        offset: options.offset,
      });

    return timeline;
  }

  isActive() {
    return this.DOM.context.classList.contains('active');
  }

  getTranslateY() {
    const inner = this.DOM.context.querySelector('.section__inner');
    const height = inner.offsetHeight;
    return Math.max(height, window.innerHeight) / 3;
  }

  getElements() {
    if (this.options.pageHeading) {
      return { heading: this.DOM.context.querySelector('.section__inner') };
    }
    const heading = this.DOM.context.querySelector('.deal__heading');
    const content = this.DOM.context.querySelector('.deal__content');
    return { heading, content };
  }

  getSlideData() {
    return {
      backgroundImage: this.DOM.context.getAttribute('data-background-image'),
      backgroundColor: this.DOM.context.getAttribute('data-background-color'),
      textColor: this.DOM.context.getAttribute('data-text-color'),
    }
  }
}
