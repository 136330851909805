import { 
  autoDetectRenderer,
  filters,
  ticker,
  WRAP_MODES,
  Container,
  Sprite,
  Texture,
} from 'pixi.js';
import anime from 'animejs';

class ImageDistorter {
  constructor(context, options) {
    this.DOM = { context };

    const defaultOptions = {
      className: 'image-distorter',
      stageWidth: 1920,
      stageHeight: 1080,
      sprites: [],
      defaultSprite: 0,
      centerSprites: false,
      autoPlay: false,
      autoPlaySpeed: [10, 3],
      fullScreen: true,
      displaceAutoFit: false,
      displaceScale: [200, 70],
      displacementImage: '',
      displacementCenter: false,
      imageScale: 1.1,
      wacky: false,
    };
    this.options = { ...defaultOptions, ...options };

    this.renderer = new autoDetectRenderer(
      this.options.stageWidth,
      this.options.stageHeight,
      { transparent: true }
    );
    this.stage = new Container();
    this.slidesContainer = new Container();
    this.displacementSprite = new Sprite.fromImage(this.options.displacementImage);
    this.displacementFilter = new filters.DisplacementFilter(this.displacementSprite);

    if (this.options.autoPlay === true) {
      const ticker = new ticker.Ticker();
      ticker.autoStart = true;

      ticker.add(delta => {
        this.displacementSprite.x += this.options.autoPlaySpeed[0] * delta;
        this.displacementSprite.y += this.options.autoPlaySpeed[1];
        this.renderer.render(this.stage);
      });

      this.ticker = ticker;
    } else {
      const render = new ticker.Ticker();
      render.autoStart = false;

      render.add(delta => {
        this.renderer.render(this.stage);
      });
      
      this.render = render;
    }

    if (this.options.displacementCenter === true) {
      this.displacementSprite.anchor.set(0.5);
      this.displacementSprite.x = this.renderer.view.width / 2;
      this.displacementSprite.y = this.renderer.view.height / 2;
    }

    this.state = {
      currentIndex: 0,
      nextIndex: null,
      isAnimating: false,
      loadedSprites: [],
    };

    this.init();
  }

  init() {
    this.initPixi();

    const defaultSpriteIndex = this.getDefaultSpriteIndex();
    
    this.options.sprites.forEach((image, index) => {
      this.addSprite(image, { alpha: index === defaultSpriteIndex ? 1 : 0});
    });
    
    this.state.currentIndex = defaultSpriteIndex;

    this.renderer.render(this.stage);
  }

  initPixi() {
    this.DOM.context.appendChild(this.renderer.view);

    this.stage.addChild(this.slidesContainer);
    this.stage.interactive = false;

    this.renderer.view.classList.add(this.options.className);
    this.renderer.view.style.top = '0';
    this.renderer.view.style.left = '50%';

    if (this.options.fullScreen === true) {
      this.renderer.view.style.objectFit = 'cover';
      this.renderer.view.style.width = '100%';
      this.renderer.view.style.height = '100%';
      const transform = 'translateX(-50%)';
      this.renderer.view.style.webkitTransform = transform;
      this.renderer.view.style.transform = transform;
    } else {
      this.renderer.view.style.maxWidth = '100%';
      const transform = 'translateX(-50%)';
      this.renderer.view.style.webkitTransform = transform;
      this.renderer.view.style.transform = transform;
    }

    this.displacementSprite.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;

    this.stage.filters = [this.displacementFilter];

    if (this.options.autoPlay === false) {
      this.displacementFilter.scale.x = 0;
      this.displacementFilter.scale.y = 0;
    }

    if (this.options.wacky === true) {
      this.displacementSprite.anchor.set(0.5);
      this.displacementSprite.x = this.renderer.width / 2;
      this.displacementSprite.y = this.renderer.height / 2;
    }

    this.displacementSprite.scale.x = 2;
    this.displacementSprite.scale.y = 2;

    // PIXI tries to fit the filter bounding box to the renderer so we optionally bypass
    this.displacementFilter.autoFit = this.options.displaceAutoFit;

    this.stage.addChild(this.displacementSprite);
  }

  getDefaultSpriteIndex() {
    if (typeof this.options.defaultSprite === 'string') {
      return this.options.sprites.indexOf(this.options.defaultSprite);
    }
    return this.options.defaultSprite;
  }

  /**
   * Adds a sprite.
   * {String} image - The url of the image
   * {Object} options - The options
   * - alpha: opacity of the image
   */
  addSprite(image, options) {
    options = { ...{
      alpha: 1,
    }, ...options };

    const texture = new Texture.fromImage(image);
    const sprite = new Sprite(texture);

    if (this.options.centerSprites === true) {
      sprite.anchor.set(0.5);
      sprite.x = this.renderer.width / 2;
      sprite.y = this.renderer.height / 2;
    }

    sprite.alpha = options.alpha;

    this.slidesContainer.addChild(sprite);
    return this.state.loadedSprites.push(image) - 1;
  }

  /**
   * Changes the current displayed image.
   * {Number|String} indexOrUrl - The index or the url of the new image
   */
  changeImage(indexOrUrl) {
    if (this.state.isAnimating) {
      const oldImage = this.slidesContainer.children[this.state.nextIndex];
      if (this.timeline && !this.timeline.completed) {
        this.timeline.pause();
      }

      anime({
        targets: oldImage,
        duration: 500,
        alpha: 0,
        easing: 'easeInCubic',
      });
    };
    
    let newIndex = indexOrUrl;
    
    if (typeof indexOrUrl === 'string') {
      newIndex = this.state.loadedSprites.indexOf(indexOrUrl);
      if (newIndex === -1) {
        newIndex = this.addSprite(indexOrUrl, { alpha: 0 });
      }
    }
    
    const oldImage = this.slidesContainer.children[this.state.currentIndex];
    const newImage = this.slidesContainer.children[newIndex];
    
    if (oldImage === newImage && oldImage.alpha === 1) return;
    
    this.state.isAnimating = true;
    this.state.nextIndex = newIndex;

    if (this.options.autoPlay !== true) {
      this.render.start();
    }

    const timeline = anime.timeline({
      update: (anim) => {
        if (this.options.wacky === true) {
          const progress = !isNaN(anim.progress) ? anim.progress / 100 : 0;
          this.displacementSprite.rotation += progress * 0.02;
          this.displacementSprite.scale.set(progress * 3);
        }
      },
      complete: () => {
        this.state.currentIndex = newIndex;
        this.state.isAnimating = false;
        this.state.nextIndex = null;
        if (this.options.wacky === true) {
          // this.displacementSprite.rotation = 0;
          this.displacementSprite.scale.set(1);
        }
        oldImage.scale.set(1);

        if (this.options.autoPlay !== true) {
          this.render.stop();
        }
      },
    });


    timeline
      .add({
        targets: this.displacementFilter.scale,
        duration: 1000,
        x: this.options.displaceScale[0], y: this.options.displaceScale[1],
        easing: 'easeInSine',
      })
      .add({
        targets: oldImage.scale,
        duration: 600,
        x: this.options.imageScale, y: this.options.imageScale,
        easing: 'easeInSine',
        offset: 0,
      })
      .add({
        targets: oldImage,
        duration: 500,
        alpha: 0,
        easing: 'easeInCubic',
        offset: 200,
      })
      .add({
        targets: newImage,
        duration: 500,
        alpha: [0, 1],
        easing: 'easeOutCubic',
        offset: 300,
      })
      .add({
        targets: newImage.scale,
        duration: 1000,
        x: [this.options.imageScale, 1], y: [this.options.imageScale, 1],
        easing: 'easeOutCubic',
        offset: 300,
      })
      .add({
        targets: this.displacementFilter.scale,
        duration: 1000,
        x: this.options.autoPlay ? 20 : 0,
        y: this.options.autoPlay ? 20 : 0,
        easing: 'easeOutCubic',
        offset: 300,
      });

    this.timeline = timeline;
  }

  destroy() {
    this.DOM.context.removeChild(this.renderer.view);
  }
}

export default ImageDistorter;
