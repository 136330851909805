import Highway from '@dogstudio/highway/dist/es5/highway';
import { loaders } from 'pixi.js';

import config from './config';
import utils from './utils';

// Views
import Home from './views/home';
import Startups from './views/startups';
import JoinUs from './views/joinUs';
import Investors from './views/investors';
import OurPartners from './views/ourPartners';
import Contact from './views/contact';
import InvestorSubmit from './views/investorSubmit';
import TalentSubmit from './views/talentSubmit';

// Components
import Loader from './components/loader';
import Header from './components/header';
import ImageDistorter from './components/imageDistorter';
import Dialog from './components/dialog';

// Transitions
import FadeTransition from './transitions/fade';
import StartupsTransition from './transitions/startups';
import InvestorsTransition from './transitions/investors';
import OurPartnersTransition from './transitions/our-partners';

class App {
  constructor() {
    this.state = {};
    this.registerConfig();
    this.loader = new Loader(document.querySelector('.loader'));
  }

  start() {
    this.preload()
    .then(() => this.init())
    .then(() => new Promise((resolve, reject) => setTimeout(resolve, 500)))
    .then(() => {
      this.loader.hide().then(() => {
        this.loader.destroy();
        delete this.loader;
      })
    });
  }

  preload() {
    // We use a Pixi loader because it appears that `utils.loadImage` doesn't loads images "enough" for Pixi...
    const loader = new loaders.Loader();
    config.preload.images.map(url => loader.add(url, url));
    return new Promise((resolve, reject) => loader.load(resolve));
  }

  init() {
    this.checkThings();

    const highway = new Highway.Core({
      renderers: {
        home: Home,
        startups: Startups,
        joinUs : JoinUs,
        investors: Investors,
        ourPartners: OurPartners,
        contact: Contact,
        investorSubmit: InvestorSubmit,
        talentSubmit: TalentSubmit,
      },
      transitions: {
        default: FadeTransition,
        startups: StartupsTransition,
        joinUs: InvestorsTransition,
        investors: InvestorsTransition,
        ourPartners: OurPartnersTransition,
      }
    });

    window.highway = highway;

    const header = new Header(document.querySelector('.masthead'));
    header.init();

    let defaultSprite = highway.props.view.getAttribute('data-background-image');

    if (highway.props.slug === 'home') {
      const sliderStartIndex = Home.getSliderStartIndex();
      if (sliderStartIndex !== 0) {
        const sliderSections = highway.props.view.querySelectorAll('.section');
        defaultSprite = sliderSections[sliderStartIndex].getAttribute('data-background-image');
      }
    }

    const imageDistorter = new ImageDistorter(document.body, {
      sprites: config.imageDistorter.images,
      defaultSprite,
      displacementImage: '/images/dmaps/2048x2048/crystalize.jpg',

      autoPlay: false,
      displaceScale: [300, 300],
      fullScreen: true,
      centerSprites: true,
      wacky: false,
    });

    // Transition out starts
    highway.on('NAVIGATE_OUT', (from, state) => {
      utils.setCursor('progress');
      document.body.style.overflow = 'hidden';
    });

    // Transition out ends, in starts
    highway.on('NAVIGATE_IN', (to, state) => {
      const viewName = to.view.getAttribute('router-view');
      utils.setCursor('progress');
      highway.emit('header::activeView', viewName);
      highway.emit('background::update', {
        backgroundImage: to.view.getAttribute('data-background-image'),
      });
    });

    // Transition in ends
    highway.on('NAVIGATE_END', (from, to, state) => {
      utils.setCursor('auto');
      document.body.style.overflow = null;
      document.body.classList.add('loaded');
      this.checkThings();
    });

    highway.on('NAVIGATE_ERROR', (error) => console.error(error));

    highway.on('background::update', data => {
      const { backgroundColor, backgroundImage, textColor } = data;
      if (backgroundColor) {
        const className = `-o-background-${backgroundColor}`;
        document.documentElement.classList.remove('-o-background-white');
        document.documentElement.classList.remove('-o-background-red');
        document.documentElement.classList.remove('-o-background-blue');
        document.documentElement.classList.add(className);
      }

      if (backgroundImage) {
        imageDistorter.changeImage(backgroundImage);
      }

      if (textColor) {
        const className = `-o-text-${textColor}`;
        document.documentElement.classList.remove('-o-text-white');
        document.documentElement.classList.remove('-o-text-blue');
        document.documentElement.classList.remove('-o-text-red');
        document.documentElement.classList.add(className);
      }
    });

    [...document.querySelectorAll('.dialog')].forEach(dialog => new Dialog(dialog));
  }

  registerConfig() {
    window.mediaQueries = config.mediaQueries;
    window.deviceList = config.deviceList;
  }

  checkThings() {
    utils.isFirefox() && document.body.classList.add('is-firefox');
    utils.isTouchDevice() && document.body.classList.add('is-touch-device');
  }
}

export default App;
