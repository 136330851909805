import anime from 'animejs';

class Header {
  constructor(context) {
    this.DOM = { context };

    this.state = {
      open: false,
      shrinked: false,
    };
  }

  init() {
    if (!this.DOM.context) {
      return;
    }
    this.DOM.context
      .querySelector('.hamburger')
      .addEventListener('click', this.toggleMenu.bind(this));

    this.getLinks().map(link => {
      link.addEventListener('click', event => {
        if (this.state.open) {
          this.toggleMenu();
        }
      });
    });

    window.highway.on('header::shrink', value => this.shrink(value));
    window.highway.on('header::activeView', viewName => this.setActive(viewName));
  }

  shrink(value) {
    if (this.state.shrinked === value) return
    this.state.shrinked = value;
    if (this.state.shrinked) {
      this.DOM.context.classList.add('is-shrinked');
    } else {
      this.DOM.context.classList.remove('is-shrinked');
    }
  }

  setActive(viewName) {
    for (const link of this.getLinks()) {
      link.classList.remove('active');
      if (link.getAttribute('data-view') === viewName) {
        link.classList.add('active');
      }
    }
  }

  toggleMenu() {
    this.state.open = !this.state.open;
    this.state.open
      ? this.DOM.context.classList.add('is-open')
      : this.DOM.context.classList.remove('is-open');

    if (this.state.open) {
      anime({
        targets: this.DOM.context.querySelectorAll('nav a'),
        duration: 500,
        scaleY: [1.5, 1],
        translateY: [-20, 0],
        opacity: [0, 1],
        easing: 'easeOutQuint',
        delay: (el, i, l) => i * 100 + 200,
      })
    }
  }

  getLinks() {
    return [...this.DOM.context.querySelectorAll('nav li')];
  }
}

export default Header;
