import Common from './common';
import StartupSlider from '../components/startupSlider';

class Startups extends Common {
  onEnter() {
    super.onEnter({ virtualScroll: { useKeyboard: false } });
    const slider = this.view.querySelector('.js_slider');
    this.slider = new StartupSlider(slider);
  }

  // onEnterCompleted() {
  //   super.onEnterCompleted();
  // }

  onLeave() {
    super.onLeave();
    this.slider.destroy();
  }

  // onLeaveCompleted() {
  //   super.onLeaveCompleted();
  // }
}


export default Startups;
