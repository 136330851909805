import anime from 'animejs';

export default class StartupSliderItem {
  constructor(context, options, slider) {
    this.DOM = { context };
    this.state = { visible: true, index: options.index };
    this.slider = slider;

    const defaultOptions = { hiddenByDefault: true };
    options = { ...defaultOptions, ...options };

    if (options.hiddenByDefault) {
      this.disappear(true, 0);
    }
    this.bindEvents();
  }

  disappear(direction, duration = 500) {
    if (this.state.currentAnimation) {
      this.state.currentAnimation.pause();
      // this.state.currentAnimation.seek(1);
    }

    const timeline = anime.timeline({
      duration,
      complete: () => {
        this.state.visible = false;
        this.state.currentAnimation = null;
      },
    });

    const DOM = this.getElements();
    timeline
      .add({
        targets: DOM.titles,
        opacity: 0,
        easing: 'easeInQuart',
      })
      .add({
        targets: DOM.description,
        offset: 100,
        opacity: 0,
        easing: 'easeInQuart',
      })
      .add({
        targets: DOM.button,
        offset: 50,
        opacity: 0,
        easing: 'easeInQuart',
      })
      .add({
        targets: DOM.image,
        offset: 30,
        scale: 0.9,
        easing: 'easeInOutQuad',
      });

    this.state.currentAnimation = timeline;
  }

  appear(direction, duration = 1000) {
    if (this.state.currentAnimation) {
      this.state.currentAnimation.pause();
    }

    const timeline = anime.timeline({
      duration,
      complete: () => {
        this.state.visible = true;
        this.state.currentAnimation = null;
      },
    });

    const DOM = this.getElements();
    timeline
      .add({
        targets: DOM.titles,
        translateX: [(direction ? -150 : 150), 0],
        opacity: [0, 1],
        easing: 'easeOutQuart',
      })
      .add({
        targets: DOM.description,
        offset: 200,
        translateX: [(direction ? -100 : 100), 0],
        opacity: [0, 1],
        easing: 'easeOutQuart',
      })
      .add({
        targets: DOM.button,
        offset: 300,
        translateX: [(direction ? -50 : 50), 0],
        opacity: [0, 1],
        easing: 'easeOutQuart',
      })
      .add({
        targets: DOM.image,
        offset: 250,
        scale: [0.9, 1],
        easing: 'easeOutQuad',
      });

    this.state.currentAnimation = timeline;
  }

  isActive() {
    return this.DOM.context.classList.contains('active');
  }

  getElements() {
    const titles = this.DOM.context.querySelector('.startup__titles');
    const description = this.DOM.context.querySelector('.startup__description');
    const button = this.DOM.context.querySelector('.startup__button');
    const image = this.DOM.context.querySelector('.startup__image');
    return { titles, description, button, image };
  }

  destroy() {
    this.unbindEvents();
  }

  bindEvents() {
    this.boundFns = {
      onClickImage: this.onClickImage.bind(this),
    };
    this.getElements().image.draggable = false;
    this.getElements().image.addEventListener('click', this.boundFns.onClickImage);
  }

  unbindEvents() {
    this.getElements().image.removeEventListener('click', this.boundFns.onClickImage);
    delete this.boundFns;
  }

  onResize() {
    if (!this.isActive()) {
      this.disappear();
    } else if (!this.state.visible) {
      this.appear();
    }
  }

  onClickImage() {
    if (this.state.index > this.slider.returnIndex()) {
      this.slider.next();
    }
  }
}
